import { initializeApp, getApp } from 'firebase/app';
import {getAnalytics, logEvent} from 'firebase/analytics';
import { getFirestore, collection, query as firestoreQuery, where, getDocs, getDoc, doc, setDoc, onSnapshot } from 'firebase/firestore';
// import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { getFunctions, httpsCallable, httpsCallableFromURL } from 'firebase/functions';
import { getAuth, signInWithEmailAndPassword, signInWithCustomToken, signOut, verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import { getDatabase, ref, get, orderByChild, startAfter, query, push, onValue } from 'firebase/database';
import { format, subDays } from "date-fns";

const app = initializeApp({
// initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});

// This initializes Analytics so it starts collecting pageviews
getAnalytics(app);

// const functions = getFunctions(app);
// connectFunctionsEmulator(functions, "localhost", 5005);

export function isLocal() {
    return document.domain === "localhost";
}

// const api = new NicaApi(firebase.database, firebase.firestore, firebase.functions, firebase.storage);
const api = {
    loginWithEmailPassword: (email, password) => {
        if (email && password) {
            return signInWithEmailAndPassword(getAuth(getApp()), email, password);
        }
        return false;
    },
    loginWithToken: (token) => {
        return signInWithCustomToken(getAuth(getApp()), token);
    },
    logout: () => {
        return signOut(getAuth(getApp()))
            .then(() => {
                return true;
            });
    },
    initiatePasswordReset: (email) => {
        return httpsCallable(getFunctions(getApp()), "auth-initiatePasswordReset")({
            email,
        });
    },
    fetchEvents: async () => {
        const snapshot = await getDocs(firestoreQuery(
            collection(getFirestore(getApp()), "events"),
            where("displayEndDate", ">=", new Date()),
            where("eventType", "==", 1),
            where("showOnEventsPage", "==", 1),
        ));

        return _docsToRecords(snapshot);
    },
    fetchEvent: async (eventId) => {
        const snapshot = await getDoc(doc(getFirestore(getApp()), "events", eventId));
        if (snapshot.exists()) {
            return {
                id: snapshot.id,
                ...snapshot.data()
            }
        }

        const querySnapshot = await getDocs(firestoreQuery(
            collection(getFirestore(getApp()), "events"),
            where("slug", "==", eventId.toLowerCase()),
        ));
        return _docsToRecords(querySnapshot)[0];
    },
    fetchActiveRegistrations: async (userId) => {
        const snapshot = await getDocs(firestoreQuery(
            collection(getFirestore(getApp()), "eventRegistrations"),
            where("userId", "==", userId),
        ));
        return _docsToRecords(snapshot);
    },
    fetchOrganizations: () => {
        return httpsCallable(getFunctions(getApp()), "data-fetchOrganizations")();
    },
    legacyAuth: (username, password) => {
        return httpsCallable(getFunctions(getApp()), "auth-legacyAuth")({ username, password });
    },
    mmfOauth: () => {
        return httpsCallable(getFunctions(getApp()), "mmf-oauth")();
    },
    fitbitOauth: () => {
        return httpsCallable(getFunctions(getApp()), "fitbit-oauth")();
    },
    garminOauth: () => {
        return httpsCallable(getFunctions(getApp()), "garmin-oauth")();
    },
    stravaOauth: () => {
        return httpsCallable(getFunctions(getApp()), "strava-oauth")();
    },
    updateEmail: (email) => {
        return httpsCallable(getFunctions(getApp()), "auth-updateEmail")({ email });
    },
    fetchLeaderboards: () => {
        const today = format(new Date(), "yyyy-MM-dd");
        return get(query(ref(getDatabase(getApp()), `leaderboards`), orderByChild("endDate"), startAfter(today))).then(snapshot => snapshot.val());
    },
    fetchEventLeaderboard: (eventId) => {
        // return get(ref(getDatabase(getApp()), `leaderboards/${eventId}`)).then(snapshot => snapshot.val());
        return httpsCallableFromURL(getFunctions(getApp()), `https://v2-leaderboard-${process.env.REACT_APP_FIREBASE_FUNCTIONS_V2_DOMAIN}`)({ eventId })
    },
    fetchEventOrganizationLeaderboard: (eventId, organizationId) => {
        return get(ref(getDatabase(getApp()), `orgLeaderboards/${eventId}/${organizationId}`)).then(snapshot => snapshot.val());
    },
    fetchEventGroupLeaderboard: (eventId, groupId) => {
        return get(ref(getDatabase(getApp()), `groupLeaderboards/${eventId}/${groupId}`)).then(snapshot => snapshot.val());
    },
    fetchEventBigTicker: (eventId) => {
        return get(ref(getDatabase(getApp()), `bigTicker/${eventId}`)).then(snapshot => snapshot.val());
    },
    fetchOrgHistory: (orgId) => {
        return get(ref(getDatabase(getApp()), `orgBigTicker/${orgId}`)).then(snapshot => snapshot.val());
    },
    checkout: (eventId, amount, token, levelId, levelName, levelColor, mileGoal, orgId, groupId, groupAccount, shirtSize) => {
        // return httpsCallableFromURL(getFunctions(getApp()), _callableUrl("v2-checkout"))({
        return httpsCallable(getFunctions(getApp()), "data-checkout")({
            eventId, amount, token, levelId, levelName, levelColor, mileGoal, orgId, groupId, groupAccount, shirtSize
        });
    },
    fetchUserProfile: async (userId) => {
        const snapshot = await getDocs(firestoreQuery(
            collection(getFirestore(getApp()), "userProfiles"),
            where("userId", "==", userId),
        ));
        const profiles = _docsToRecords(snapshot);
        return profiles[0];
    },
    watchUserProfile: async (userId, onProfileUpdate) => {
        const userProfileQuery = firestoreQuery(
            collection(getFirestore(getApp()), "userProfiles"),
            where("userId", "==", userId),
        );

        // returns the unsub
        return onSnapshot(userProfileQuery, (querySnapshot) => {
            querySnapshot.forEach(snapshot => {
                onProfileUpdate({
                    id: snapshot.id,
                    ...snapshot.data(),
                });
            })
        });
    },
    watchEventRegistrations: async (userId, onRegistrations) => {
        const query = firestoreQuery(
            collection(getFirestore(getApp()), "eventRegistrations"),
            where("userId", "==", userId),
            where("eventEndDate", ">=", subDays(new Date(), 2))
        );

        // returns the unsub
        return onSnapshot(query, (querySnapshot) => {
            onRegistrations(_docsToRecords(querySnapshot));
            // querySnapshot.forEach(snapshot => {
            //     onR({
            //         id: snapshot.id,
            //         ...snapshot.data(),
            //     });
            // })
        });
    },
    fetchAllEventRegistrations: async (userId) => {
        const querySnapshot = await getDocs(firestoreQuery(
            collection(getFirestore(getApp()), "eventRegistrations"),
            where("userId", "==", userId),
        ));

        // const querySnapshot = await getDocs(firestoreQuery(
        //     collection(getFirestore(getApp()), "events"),
        //     where("slug", "==", eventId),
        // ));
        return _docsToRecords(querySnapshot);
    },
    fetchPastEventRegistrations: async (year, userId) => {
        const currentYear = new Date().getFullYear();
        const endDate = year === currentYear ? new Date() : `${year}-12-31`;

        console.log("fetchPastEventRegistrations", year);

        const querySnapshot = await getDocs(firestoreQuery(
            collection(getFirestore(getApp()), "eventRegistrations"),
            where("userId", "==", userId),
            where("eventEndDate", "<=", endDate),
            where("eventEndDate", ">=", new Date(`${year}-01-01`)),
        ));

        // const querySnapshot = await getDocs(firestoreQuery(
        //     collection(getFirestore(getApp()), "events"),
        //     where("slug", "==", eventId),
        // ));
        return _docsToRecords(querySnapshot);
    },
    updateUserProfile: async (profile) => {
        return await setDoc(doc(getFirestore(getApp()), "userProfiles", profile.id), profile);
    },
    updateEventRegistration: async (registration) => {
        // return httpsCallable(getFunctions(getApp()), "data-updateEventRegistration")({
        //     eventId, goal, orgId, groupId
        // });
        return await setDoc(doc(getFirestore(getApp()), "eventRegistrations", registration.id), registration);
    },
    fetchRecentActivity: (offset, limit) => {
        // return httpsCallableFromURL(getFunctions(getApp()), _callableUrl("v2-recentactivity"))({ offset, limit });
        return httpsCallable(getFunctions(getApp()), "data-fetchRecentActivity")({ offset, limit });
    },
    logActivity: (userProfileId, date, activityTypeId, miles, minutes) => {
        return httpsCallable(getFunctions(getApp()), "activity-logActivity")({
            userProfileId, date, activityTypeId, miles, minutes
        });
    },
    deleteActivity: (activityId) => {
        return httpsCallable(getFunctions(getApp()), "data-deleteActivity")({
            activityId
        });
    },
    registerUser: (email, password) => {
        return httpsCallable(getFunctions(getApp()), "auth-registerUser")({
            email, password
        });
    },
    exportOrgLeaderboard: (eventId, orgId) => {
        return httpsCallable(getFunctions(getApp()), "data-exportOrgLeaderboard")({
            eventId, orgId
        });
    },
    verifyResetPasswordCode: (actionCode) => {
        return verifyPasswordResetCode(getAuth(getApp()), actionCode);
            // .then((email) => {
            //     var accountEmail = email;
            //
            //     // TODO: Show the reset screen with the user's email and ask the user for
            //     // the new password.
            //     var newPassword = "...";
            //
            //     // Save the new password.
            //     auth.confirmPasswordReset(actionCode, newPassword).then((resp) => {
            //         // Password reset has been confirmed and new password updated.
            //         // TODO: login with email / pass
            //
            //     }).catch((error) => {
            //         // Error occurred during confirmation. The code might have expired or the
            //         // password is too weak.
            //     });
            // }).catch((error) => {
            //     // Invalid or expired action code. Ask user to try to reset the password
            //     // again.
            // });
    },
    confirmPasswordReset: (actionCode, password) => {
        return confirmPasswordReset(getAuth(getApp()), actionCode, password);
    },
    disconnectFitbit: async (profile) => {
        const updatedProfile = {
            ...profile,
            fitbit: {},
        }
        await api.updateUserProfile(updatedProfile);

        return updatedProfile;
    },
    disconnectMmf: async (profile) => {
        const updatedProfile = {
            ...profile,
            mmf: {},
        }
        await api.updateUserProfile(updatedProfile);

        return updatedProfile;
    },
    disconnectGarmin: async (profile) => {
        const updatedProfile = {
            ...profile,
            garmin: {},
        }
        await api.updateUserProfile(updatedProfile);

        return updatedProfile;
    },
    disconnectStrava: async (profile) => {
        const updatedProfile = {
            ...profile,
            strava: {},
        }
        await api.updateUserProfile(updatedProfile);

        return updatedProfile;
    },
    fetchYtdStats: (userId, year) => {
        return get(ref(getDatabase(getApp()), `ytdStats/${userId}/${year}`)).then(snapshot => snapshot.val());
    },
    submitFeedback: (message, email = "") => {
        return push(ref(getDatabase(getApp()), `feedback`), {
            createdAt: new Date().toString(),
            message,
            email
        });
    },
    impersonateUser: (email) => {
        return httpsCallable(getFunctions(getApp()), "auth-impersonateUser")({
            email
        });
    },
    impersonateSteve: () => {
        return api.impersonateUser("steve@healthcode.org");
    },
    logEvent: (eventName, metadata) => {
        logEvent(getAnalytics(getApp()), eventName, metadata);
    },
    fetchEventStats: async (eventId, userId, orgId, groupId) => {
        const paths = ["users", "walkRun", "bikeCycle", "swimming"];
        if (orgId) {
            paths.push(`orgs/${orgId}`);
        }
        if (groupId) {
            paths.push(`groups/${groupId}`)
        }

        const results = await Promise.all(paths.map(path => get(ref(getDatabase(getApp()), `eventStats/${eventId}/${path}/${userId}`)).then(snapshot => snapshot.val())));
        const map = paths.reduce((ret, path, index) => {
            return {
                ...ret,
                [path]: results[index]
            }
        }, { eventId });
        if (orgId) {
            map.orgs = results[4];
        }
        if (groupId) {
            map.groups = results[5];
        }
        return map;
    },
    fetchUserEventStats: (eventId, userId) => {
        return get(ref(getDatabase(getApp()), `eventUserTotals/${eventId}/${userId}`)).then(snapshot => snapshot.val());
    },
    watchUserEventStats: (eventId, userId, onEventStats) => {
        return onValue(ref(getDatabase(getApp()), `eventUserTotals/${eventId}/${userId}`), snapshot => {
            onEventStats(snapshot.val())
        });
    }
};

function _docsToRecords(querySnapshot) {
    const results = [];
    querySnapshot.forEach(doc => results.push({
        id: doc.id,
        ...doc.data(),
    }));
    return results;
}

// function _callableUrl(functionName) {
//     return `https://${functionName}-${process.env.REACT_APP_FIREBASE_FUNCTIONS_V2_DOMAIN}`
// }

export default api;
